import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { initFormController } from '@wix/form-viewer/controller';
import { getFormId } from '../../services/getFormId';

const FORMS_NAMESPACE = 'wix.form_composer_dev.form';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const { setProps } = controllerConfig;

  return {
    async pageReady() {
      const formId = await getFormId(flowAPI.httpClient);
      await initFormController(flowAPI, { formId, namespace: FORMS_NAMESPACE });
      setProps({ formId });
    },
  };
};

export default createController;
