import { type IHttpClient } from '@wix/yoshi-flow-editor';
import { getComponentSettings } from '@wix/ambassador-app-settings-v1-settings/http';

const COMPONENT_ID = 'simasb-onboarding-form';

export async function getFormId(httpClient: IHttpClient): Promise<string> {
  const options = getComponentSettings({ componentId: COMPONENT_ID });
  const result: any = await httpClient.request(options);

  const formId = result?.data?.settings?.CUSTOM;

  if (!formId) {
    throw Error('No last created form ID found');
  }

  return formId;
}
